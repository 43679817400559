import React from "react";
import moment from "moment";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import VerkoopPdf from '../../components/pdf/verkooppdf';

class exportStats extends React.Component {

    render() {return (<div></div>)}
	
	exportPDF = (stats, info) => {
		var baromzethoog = 0;
		var baromzetlaag = 0;
		var baromzet13 = 0;
		var startstr = '';
		var endstr = '';
		Object.keys(stats).sort().forEach((dagstr, ind) => {
			var statsDate = stats[dagstr];	
            if(ind === 0){startstr = dagstr};
            if(ind === (Object.keys(stats).length - 1)){endstr = dagstr};						
            if(statsDate.afhaalpunten){				
                Object.keys(statsDate.afhaalpunten).forEach(afhaalpunt => {					
                    Object.keys(statsDate.afhaalpunten[afhaalpunt].items).forEach(item => {
						if(statsDate.afhaalpunten[afhaalpunt].items[item].rekening){
							if(statsDate.afhaalpunten[afhaalpunt].items[item].btw === 0.09){
								baromzetlaag = baromzetlaag + statsDate.afhaalpunten[afhaalpunt].items[item].rekening
							}						
							if(statsDate.afhaalpunten[afhaalpunt].items[item].btw === 0.21){
								baromzethoog = baromzethoog + statsDate.afhaalpunten[afhaalpunt].items[item].rekening;
							}
							if(statsDate.afhaalpunten[afhaalpunt].items[item].btw === 0.13){
								baromzet13 = baromzet13 + statsDate.afhaalpunten[afhaalpunt].items[item].rekening;
							}
						}
                    });
                });				
            }
		});		
		var datestr = '' + moment(new Date(startstr.replace(/-/g, ","))).format("DD-MM-YYYY") + ' t/m ' + moment(new Date(endstr.replace(/-/g, ","))).format("DD-MM-YYYY");
		var totaal = baromzetlaag + baromzethoog + baromzet13;
		var totaalExcl = (baromzetlaag / 1.09) + (baromzethoog / 1.21) + (baromzet13 / 1.13);
		var totaalBTW = ((baromzetlaag * 9) / 109) + ((baromzethoog * 21) / 121) + ((baromzet13 * 13) / 113);
		var statObj = [
			{"Omschrijving": "OMZET BARREN LAAG", "Periode": datestr, "BTW %": 0.09, "Omzet excl.": this.tls(baromzetlaag / 1.09), "BTW": this.tls((baromzetlaag * 9) / 109), "Omzet incl.": this.tls(baromzetlaag)},
			{"Omschrijving": "OMZET BARREN HOOG", "Periode": datestr, "BTW %": 0.21, "Omzet excl.": this.tls(baromzethoog / 1.21), "BTW": this.tls((baromzethoog * 21) / 121), "Omzet incl.": this.tls(baromzethoog)},
			{"Omschrijving": "OMZET BARREN 13%", "Periode": datestr, "BTW %": 0.13, "Omzet excl.": this.tls(baromzet13 / 1.13), "BTW": this.tls((baromzet13 * 13) / 113), "Omzet incl.": this.tls(baromzet13)},
			{"Omschrijving": "TOTAAL", "Periode": datestr, "BTW %": "", "Omzet excl.": this.tls(totaalExcl), "BTW": this.tls(totaalBTW), "Omzet incl.": this.tls(totaal)}
		];
		this.generatePDFDocument(statObj,info,datestr);
	}

	generatePDFDocument = async (data,info,datestr) => {
		const blob = await pdf(<VerkoopPdf data={data} exportDate={datestr} exportInfo={info} title="Uitdraai Rekening" />).toBlob();
		saveAs(blob, 'Partypay_Export_' + info.evt + '.pdf');
	};

    tls = (num) => {
        return parseFloat((Math.round(num * 100) / 100).toFixed(2))
    }


}

export default exportStats;



